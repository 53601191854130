<template>
	<a
		class="flex justify-between rounded border border-l-0"
		:class="[
			notification.read_at == null ? 'bg-purple-600 bg-opacity-15 hover:bg-opacity-20' : 'bg-white hover:bg-gray-200',
		]"
		:href="getRoute"
		@click.prevent="handledNotification()"
	>
		<div
			class="w-1 bg-purple-600"
			:style="{ height: 'unset' }"
		/>

		<div class="flex justify-between flex-1 items-center py-3 px-5">
			<div class="leading-tight space-y-1.5">
				<p class="font-semibold block text-base">
					{{ notification.subject }}
				</p>
				<p class="text-sm">{{ notification.content }}</p>
				<p
					class="text-xs font-medium"
					:style="{ color: '#8E8B98' }"
				>
					{{ getDate }}
				</p>
			</div>

			<div class="flex items-center space-x-5">
				<div
					v-if="notification.read_at == null"
					class="w-2 h-2 rounded-full bg-purple-600"
				/>

				<button @click.stop.prevent="changeAnchorNotification(notification.id)">
					<Icon
						v-if="notification.is_anchored"
						name="material-symbols:push-pin"
						size="20"
					/>
					<Icon
						v-else
						name="material-symbols:push-pin-outline"
						size="20"
					/>
				</button>
			</div>
		</div>
	</a>
</template>

<script setup>
import { useNotificationsStore } from '~/store/notifications'

const { $date, $tracking } = useNuxtApp()
const router = useRouter()
const localePath = useLocalePath()
const { setReadNotification, changeAnchorNotification } = useNotificationsStore()
const notificationsStore = useNotificationsStore()

const props = defineProps({
	notification: {
		type: Object,
		required: true,
	},
})

const emit = defineEmits(['interaction'])

const getRoute = computed(() => {
	return props.notification.url
})

const getDate = computed(() => {
	return $date().to($date(props.notification.created_at))
})

const handledNotification = () => {
	router.push(localePath(getRoute.value))
	emit('interaction', props.notification)
	setReadNotification(props.notification)
	notificationsStore.isOpenList = false
	$tracking.notifications.backFromNotificationsEvent(props.notification)
}
</script>
