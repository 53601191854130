<template>
	<div class="flex items-center">
		<button
			class="text-gray-700 rounded-full focus:outline-none focus:shadow-outline relative w-10 h-10 bg-neutral-50 hover:bg-gray-200 flex items-center justify-center"
			aria-label="Notifications"
			@click.stop="notificationsStore.isOpenList = !notificationsStore.isOpenList"
		>
			<Icon
				name="uil:bell"
				size="22"
			/>

			<span class="absolute -top-1.5 left-5">
				<span
					v-if="unReadCount > 0"
					style="min-width: 18px"
					class="h-5 px-1 bg-red-600 rounded-full flex items-center justify-center"
				>
					<span class="text-xs text-white font-semibold">
						{{ getCountNotification }}
					</span>
				</span>
			</span>
		</button>

		<USlideover v-model="notificationsStore.isOpenList">
			<ListNotifications
				@close="notificationsStore.isOpenList = false"
				@interaction="hasInteraction"
			/>
		</USlideover>
	</div>
</template>

<script setup>
import { useNotificationsStore } from '~/store/notifications'
import ListNotifications from '~/components/notifications/ListNotifications.vue'

const { $tracking } = useNuxtApp()
const { unReadCount } = storeToRefs(useNotificationsStore())
const { fetchNotifications } = useNotificationsStore()
const notificationsStore = useNotificationsStore()

// const isOpenBoxNotifications = ref(false)

const getCountNotification = computed(() => {
	return unReadCount.value > 99 ? '99+' : unReadCount.value
})

const openNotification = () => {
	$tracking.storage.set('notifications', { startedAt: new Date() })
}

const hasInteraction = () => {
	$tracking.storage.set('notifications', { noInteraction: false })
}

const closeNotification = () => {
	$tracking.notifications.closeBoxNotifications(unReadCount.value)
}

watch(notificationsStore.isOpenList, (value) => {
	if (value) {
		openNotification()
	} else {
		closeNotification()
	}
})

onMounted(() => {
	fetchNotifications()
	// fetchNotificationPreferences()
})
</script>
