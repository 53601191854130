<script setup lang="ts">
import { SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME } from '~/config/featureFlags'

const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const { $tracking, $subscription, $hasFeature, $auth } = useNuxtApp()
const { t: $t } = useI18n()
const { createOfferPath } = useCreateOffer()

const canCreateOffer = computed<boolean>(() => {
	return $auth.user.business.rules.can_create_work_offers
})

const isSearchFilterApplied = computed(() => {
	const { query } = route
	if (query === '{}') return false

	return route.query?.applied_filter
})

const getSearchFilterParameters = computed(() => {
	const parametersToFilter = ['role_id']
	const query = route.query

	return Object.keys(query).reduce((obj, key) => {
		if (parametersToFilter.includes(key)) {
			obj[key] = query[key]
		}
		return obj
	}, {})
})

const goToCreateOffer = () => {
	$tracking.offer.offerCreationView()

	if (!isSearchFilterApplied.value) {
		router.push(localePath(createOfferPath.value))
		return
	}

	router.push(
		localePath({
			path: createOfferPath.value,
			query: getSearchFilterParameters.value,
		}),
	)
}
</script>

<template>
	<div
		v-if="!$hasFeature(SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME)"
		class="hidden 2xl:grid"
	>
		<UButton
			size="sm"
			variant="outline"
			:to="$subscription.isSubscriptionFree ? '/subscription/buy' : '/subscription/update'"
		>
			<Icon
				name="material-symbols:star"
				class="mr-1"
				size="20"
			/>
			Actualizar plan
		</UButton>
	</div>

	<div
		v-if="canCreateOffer"
		class="hidden 2xl:grid"
	>
		<UButton
			size="sm"
			@click="goToCreateOffer"
		>
			<Icon
				name="uil:plus"
				class="mr-1"
				size="20"
			/>
			{{
				$hasFeature(SQUAD_04_20241128_PLAN_SELECT_HIRE_REDESIGN_HOME) ? $t('Crear oferta') : $t('Configura tu búsqueda')
			}}
		</UButton>
	</div>
</template>

<style scoped></style>
